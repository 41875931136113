import React, { useState, useEffect } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap'
import ProductData from './ProductData'
import useAxiosInstance from '../../Api/AxiosInstance';

function Cart() {

  let [shipping, setShipping] = useState(15)
  let [vat, setVat] = useState(0)
  const [cartitems, setCartitems] = useState([]);
  const AxiosInstance = useAxiosInstance()


  let sum = 0
  for (let i = 0; i < cartitems.length; i++) {
    sum += cartitems[i].sub_total
  }

  let total = sum + shipping + vat

  const fetchProduct = async () => {
    try {
      const response = await AxiosInstance.post('cart-lists')
      setCartitems(response.data.data);
    } catch (error) {

    }
  }

  useEffect(() => {
    fetchProduct()
  }, [])

  const quantityCount = async (data) => {
    const response = await AxiosInstance.post('update-cart-lists', { cart_id: data[0], quantity: data[1] })
    fetchProduct()
  }


  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>
          <DashboardTitle />
          <div className='cart-page-main'>
            <div className='breadcrumb-block ptb-20 pt-0'>
              <ul className='breadcrumb-list d-flex v-center'>
                <li><Link to='/dashboard'>Home</Link></li>
               {/* <li><Link to='#'>Order medicine</Link></li>*/}
                <li>Cart</li>
              </ul>
            </div>
            {
              cartitems.length !== 0 ?
                <Form className='cart-form'>
                  <Row className='cart-blocks'>
                    <Col xs={12} md={8} lg={8} className='left-block'>
                      <div className='white_box cart-list-wrapper'>
                        {cartitems?.map((item, index) =>
                          <div className='cart-items d-flex v-center' key={index}>
                            <div className='cart-imgbox d-flex'>
                              <img src={item.image} alt='' />
                            </div>
                            <div className='cart-info-textbox'>

                              <div className='sub-item d-flex'>
                                <label className="cart-label">Product Name :</label>
                                <span className='cart-value'>{item.name}</span>
                              </div>

                              <div className='sub-item d-flex'>
                                <label className="cart-label">Price :</label>
                                <span className='cart-value'>₹ {item.cart_price.toFixed(2)}</span>
                              </div>

                              <div className='sub-item d-flex'>
                                <label className="cart-label">Quantity :</label>
                                <div className='cart-inputbox d-flex v-center'>
                                  <button type='button' className='decr_minus' onClick={() => quantityCount([item.cart_id, item.quantity - 1])}>-</button>
                                  <div className='cart_number'>{item.quantity}</div>
                                  <button type='button' className='decr_minus' onClick={() => quantityCount([item.cart_id, item.quantity + 1])}>+</button>
                                </div>
                              </div>

                              <div className='sub-item d-flex'>
                                <label className="cart-label">Subtotal :</label>
                                <span className='cart-value'>₹ {item.sub_total.toFixed(2)}</span>
                              </div>

                            </div>
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} md={4} lg={4} className='right-block'>
                      <div className='white_box cart-total-wrapper'>
                        <div className='box-title text-center'>
                          <h4>Cart Total</h4>
                        </div>

                        <div className='cart-total-list'>
                          <div className='sub-item d-flex'>
                            <label className="cart-label">Subtotal</label>
                            <span className='cart-value'>₹ {sum.toFixed(2)}</span>
                          </div>

                          <div className='sub-item d-flex'>
                            <label className="cart-label">Shipping and Handing</label>
                            <span className='cart-value'>₹ {shipping.toFixed(2)}</span>
                          </div>

                          <div className='sub-item d-flex'>
                            <label className="cart-label">VAT</label>
                            <span className='cart-value'>₹ {vat.toFixed(2)}</span>
                          </div>

                          <div className='sub-item d-flex grand-total-item'>
                            <label className="cart-label"><strong>Total</strong></label>
                            <span className='cart-value'><strong>₹ {total.toFixed(2)}</strong></span>
                          </div>

                          <div className='sub-item d-flex form-btnbox'>
                            <Link className='btn secondary w-100' to='/checkout/'>Proceed to checkout <i className="las la-shopping-cart ml-2"></i></Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
                :
                <>
                  <div className='white_box profile-form-block'>
                    <h2 className='text-center'>Your Cart is empty.</h2>
                    <h4 className='text-center'>You can upload prescription and buy medicine from dashboard.</h4>
                  </div>
                  {/*<ProductData />*/}
                </>

            }
          </div>
        </div>
      </section>
    </div>
  )
}
export default Cart