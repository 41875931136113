import React, {useState} from 'react';
import Form from "react-validation/build/form";

function UserInfo() {
  const user = JSON.parse(localStorage.getItem("user"));
    const [selectedLanguage, setSelectedLanguage] = useState("hindi");

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };
  return (
    <div className='white_box account-blocks h-100'>

      <div className='box-title'>
        <h4>User Information</h4>
      </div>
      
      <div className='user-info-block'>
        <div className='user-sub-items d-flex'>
          <label className='user-label'>EMI Status :</label>
          <button type='button' className='btn primary small'>
            {
              user?.user?.emi_approve_status === 1 ? "In Progress" :
                  user?.user?.emi_approve_status === 2 ? "Approved" :
                      user?.user?.emi_approve_status === 3 ? "Rejected" :
                          "Unknown"
            }
          </button>

        </div>
        <div className='user-sub-items d-flex'>
          <label className='user-label'>Full Name :</label>
          <span className='user-value'>{user?.user.full_name}</span>
        </div>
        <div className='user-sub-items d-flex'>
          <label className='user-label'>Phone Number :</label>
          <span className='user-value'>+ 91 {user?.user.mobile_no}</span>
        </div>

        <div className='user-sub-items d-flex'>
          <label className='user-label'>Email ID :</label>
          <span className='user-value'>{user?.user.email_id}</span>
        </div>
          <hr/>
        <div classNam='video-option-block-main'>
            <div className='option-drop-block ptb-20 pt-0'>
                <Form className='form-inner emi-form-main'>
                    <div className='input-field full-width'>
                        <label className='label'>Preferred Language </label>
                        <select aria-label="select-language"
                                name="preferredLanguage"
                                onChange={handleLanguageChange}
                        >
                            <option value="hindi">Hindi</option>
                            <option value="english">English</option>
                        </select>
                    </div>
                </Form>
            </div>

            {selectedLanguage === "hindi" && (
                <div className="videobox">
                    <video
                        src="https://eugenix.windzoon.in/assets/uploads/patient/EMI%20video%20hindi.mp4"
                        controls
                        width="100%"
                        alt="Hindi EMI Video"
                    >
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}

            {selectedLanguage === "english" && (
                <div className="videobox">
                    <video
                        src="https://eugenix.windzoon.in/assets/uploads/patient/EMI video English.mp4"
                        controls
                        width="100%"
                        alt="English EMI Video"
                    >
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}


        </div>
        {/*<div className='imgbox'>
          <video
              src="https://eugenix.windzoon.in/assets/uploads/patient/EMI%20video%20hindi.mp4"
              controls
              width="600"
              alt="EMI Video"
          >
            Your browser does not support the video tag.
          </video>

        </div>*/}
        {/* <div className='user-sub-items d-flex'>
          <label className='user-label'>Date of Birth :</label>
          <span className='user-value'>{user?.user.date_of_birth}</span>
        </div> */}

        {/*<div className='user-sub-items d-flex'>*/}
        {/*  <label className='user-label'>City :</label>*/}
        {/*  <span className='user-value'>Ahmedabad</span>*/}
        {/*</div>*/}

        {/*<div className='user-sub-items d-flex'>*/}
        {/*  <label className='user-label'>State :</label>*/}
        {/*  <span className='user-value'>Gujarat</span>*/}
        {/*</div>*/}

        {/*<div className='user-sub-items d-flex'>*/}
        {/*  <label className='user-label'>Country :</label>*/}
        {/*  <span className='user-value'>{user.email_id}</span>*/}
        {/*</div>*/}

      </div>

    </div>
  )
}
export default UserInfo