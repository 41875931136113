import React, { useState } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Row, Col, Form, Button } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import { Formik, ErrorMessage } from 'formik';
import { EditProfileSchema } from './commonValidation';
import useAxiosInstance from '../../Api/AxiosInstance';


function Dashboard() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState({
    image: user?.user?.image_url ? user?.user?.image_url : user?.user?.image
  })
  const [file, setFile] = useState(data.image);
  const [imageerr, setImageerr] = useState('')
  const [selectedFile, setSelectedFile] = useState("")
  const dob = user?.user?.dob ? user?.user?.dob : '01-01-1970'
  const AxiosInstance = useAxiosInstance()




  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ['image/png', 'image/jpeg', 'image/gif'];
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setSelectedFile(selectedFile);
      setFile(URL.createObjectURL(selectedFile)); // Set file state to the URL of the selected file
      setImageerr(''); // Clear any previous error message
    } else {
      setImageerr('Only png, jpeg, and gif files are allowed');
    }
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('full_name', values.full_name);
      formData.append('email_id', values.email_id);
      formData.append('mobile_no', values.mobile_no);
      formData.append('gender', values.gender);
      // formData.append('dob', values.dob);
      if (selectedFile) {
        formData.append('image', selectedFile);
      }

      const response = await AxiosInstance.post('user/update', formData)
      if (response.data.success == 1) {
        toast.success('Profile updated successfully.', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        const updatedUser = {
          ...user,
          user: {
            ...user.user,
            full_name: response.data.data.user.full_name,
            email_id: response.data.data.user.email_id,
            gender: response.data.data.user.gender,
            dob: response.data.data.user.dob,
            mobile_no: response.data.data.user.mobile_no,
            date_of_birth: response.data.data.user.date_of_birth,
            image_url: response.data.data.user.image_url
          },
        };
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setTimeout(() => {
          window.location.reload()
        }, 1800);

      } else {
        console.error('API response does not contain updated user data');
      }

    } catch (error) {
      console.log(error)
    }
  }


  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className='main-section'>
        <div className='main-inner'>
          <DashboardTitle />
          <Formik
            initialValues={{
              full_name: user.user.full_name,
              email_id: user.user.email_id,
              mobile_no: user.user.mobile_no,
              gender: user.user.gender,
              // dob: user.user.dob ? user.user.dob : '1970-01-01',
              image: file
            }}
            validationSchema={EditProfileSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, handleChange, values, touched }) => (

              <form className='edit-form-main' onSubmit={handleSubmit}>
                <Row className='dashboard-blocks'>
                  <Col xs={12} md={8} lg={8} className='left-block'>

                    <div className='white_box profile-form-block'>

                      <div className='box-title'>
                        <h3>Edit Profile</h3>
                      </div>

                      <div className='form-inner d-flex'>
                        <div className='input-field'>
                          <label className='label'>Full Name</label>
                          <input type='text' name='full_name' placeholder='Enter Full Name' value={values.full_name} onChange={handleChange} disabled />
                        </div>

                        <div className='input-field'>
                          <label className='label'>Mobile No.</label>
                          <input type='text' inputMode="numeric" name='mobile_no' placeholder='Enter Mobile No.' value={values.mobile_no} onChange={handleChange}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/\D/g, '');
                              e.target.value = e.target.value.slice(0, 10);
                            }} />
                          <ErrorMessage name="mobile_no" >
                            {msg => (
                              <div className="alert alert-danger mt-3" role="alert">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <div className='input-field'>
                          <label className='label'>Email ID</label>
                          <input type='email' name='email_id' placeholder='Enter Email Id' value={values.email_id} disabled />
                        </div>

                        {/* <div className='input-field'>
                          <label className='label'>Date of Birth</label>
                          <input type='date' name='dob' value={values.dob} onKeyDown={(e) => e.preventDefault()} onChange={handleChange} />
                          <ErrorMessage name="dob" >
                            {msg => (
                              <div className="alert alert-danger mt-3" role="alert">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div> */}

                        <div className='input-field'>
                          <label className='label'>Gender</label>
                          <div className="option-block d-flex v-center">
                            <label className="radiobox">
                              <input type="radio" name="gender" value={1} checked={values.gender == '1'} onChange={handleChange} />
                              <span className="checkmark"></span> Male
                            </label>
                            <label className="radiobox">
                              <input type="radio" name="gender" value={2} checked={values.gender == '2'} onChange={handleChange} />
                              <span className="checkmark"></span> Female
                            </label>
                          </div>
                          <ErrorMessage name="gender" >
                            {msg => (
                              <div className="alert alert-danger mt-3" role="alert">{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>

                        <Form.Group className='input-field full-width form-btnbox d-flex mb-0 pt-2'>
                          <Button className='btn secondary ml-auto' variant="primary" type="submit">Update Profile</Button>
                        </Form.Group>

                      </div>
                    </div>

                  </Col>

                  <Col xs={12} md={4} lg={4} className='right-block'>
                    <div className='white_box prifile-about text-center'>
                      <div className='imgbox'>
                        <div className='profile-update-imgbox relative'>
                          <img src={file} />
                          <label className='upload-img d-flex v-center j-center'>
                            <input type="file" name='image' accept="image/png, image/jpeg , image/gif" onChange={handleFileChange} />
                            <i className="las la-image"></i><span>Change/Edit Your Profile Image</span>
                          </label>
                        </div>
                      </div>

                      <div className='textbox'>
                        <h4>{user.user.full_name}</h4>
                        {user?.user?.bitrix_lead_id && <h4>  Bitrix ID: {user.user.bitrix_lead_id}</h4>}
                        <p>Only (.png  .jpeg  .gif) are allowed</p>
                      </div>

                      {imageerr && (
                        <div className="alert alert-danger mt-3" role="alert">{imageerr}</div>
                      )}
                    </div>
                  </Col>

                </Row>
              </form>
            )}
          </Formik>


        </div>
      </section>
    </div>
  )
}
export default Dashboard