import React, { useState, useEffect } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Row, Col, Table } from 'react-bootstrap';
import useAxiosInstance from '../../Api/AxiosInstance';
import configData from "../../config.json";

function MyNotification() {
    const [isLoaded, setIsLoaded] = useState(false); // Ensure the loading state is correctly handled
    const [items, setItems] = useState([]); // Notifications list
    const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
    const [itemsPerPage] = useState(configData.DATA_PER_PAGE); // Number of items per page
    const [totalItems, setTotalItems] = useState(0); // Total number of items

    const AxiosInstance = useAxiosInstance(); // Axios instance for making API requests

    // Handle marking notification as read/unread
    const handleRemove = async (index, status) => {
        try {
            await AxiosInstance.post('mark-as-read', { id: index, status: status });
            fetchOrder(); // Re-fetch the data after marking as read/unread
        } catch (error) {
            console.error('Error updating notification status:', error);
        }
    };

    // Fetch notifications and apply pagination
    const fetchOrder = async () => {
        try {
            const response = await AxiosInstance.post('my-notifications');
            const allData = response.data.data.notifications;

            // Sort notifications by created_at field (newest first)
            const sortedData = allData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            // Update total number of notifications
            setTotalItems(sortedData.length);

            // Handle pagination (get the subset of notifications for the current page)
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const subset = sortedData.slice(startIndex, endIndex);

            // Update items with the paginated data
            setItems(subset);
            setIsLoaded(true); // Set loading to false once the data is loaded
        } catch (error) {
            console.error('Error fetching notifications:', error);
            setIsLoaded(true); // Set loading to false if error occurs
        }
    };

    // UseEffect to fetch data when the component mounts or when the page changes
    useEffect(() => {
        fetchOrder();
    }, [currentPage]);  // Re-fetch data when currentPage changes

    return (
        <div className='main-page bg4'>
            <DashboardHeader />
            <DashboardSidebar />
            <section className='main-section'>
                <div className='main-inner'>
                    <DashboardTitle />
                    <div className='white_box account-blocks'>
                        <Row className='box-title v-center'>
                            <Col xs={12} md={6} lg={6} className='left-block'>
                                <h4>All Notifications</h4>
                            </Col>
                        </Row>
                        <Row className='dashboard-blocks'>
                            <Col xs={12} md={12} lg={12} className='left-block'>
                                <div className='account-table-block'>
                                    <Table className='w-100' bsPrefix borderless hover>
                                        <thead>
                                        <tr>
                                            <th width='400'>Notification</th>
                                            <th width='50'>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {items?.length > 0 ? (
                                            items.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.message}</td>
                                                    <td>
                                                        {item.is_read == 0 ? (
                                                            <button
                                                                className="btn small-btn red-btn"
                                                                onClick={() => handleRemove(item.chat_support_id, 1)} // Mark as read
                                                                type="button" >
                                                                Mark as read
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn btn-primary small-btn"
                                                                onClick={() => handleRemove(item.chat_support_id, 0)} // Mark as read
                                                                type="button" >
                                                                Mark as unread
                                                            </button> // Should it display something else when it's read?
                                                        )}

                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="2" className="text-lg-center">
                                                    No Data Found.
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        {/* Add pagination here if needed */}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default MyNotification;
