import React, { useState, useEffect } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Pagination, Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap';
import review_img2 from '../../assets/images/dr.png';
import configData from "../../config.json";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import useAxiosInstance from '../../Api/AxiosInstance';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import Moment from 'moment';
import product_img from '../../assets/images/dr.png';
import thankyou_icon from '../../assets/images/thankyou_icon.svg';
import ChatBlock from './dashboard_modules/ChatBlock';

function renderEventContent(eventInfo) {
  return (
    <>
      <div className='avail_app'>
        <img src={eventInfo.event.url} width="25px" /><br></br>
        <i>{eventInfo.event.title}</i>
      </div>
    </>
  )
}

function MyAccount() {

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(configData.DATA_PER_PAGE);
  const [totalItems, setTotalItems] = useState(0);
  const AxiosInstance = useAxiosInstance()
  const [editpop, setEditpop] = useState(false)
  const [reschedulepop, setReshedulepop] = useState(false)
  const handleClose = () => { setEditpop(false); setReshedulepop(false); setThankyou(false); setEditData('') }
  const availabilityData = [];
  const [events, setEvents] = useState([]);
  const [editData, setEditData] = useState('')
  const [thankyou, setThankyou] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(false);



  useEffect(() => {
    fetchAppointments();
  }, [currentPage, thankyou]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Reschedule / Cancel
    </Tooltip>
  );

  const fetchAppointments = async () => {
    try {
      const response = await AxiosInstance.post('my-appointments')
      const allData = Object.values(response.data.data);
      const sortedData = allData.sort((a, b) => new Date(b.schedule_date) - new Date(a.schedule_date));
      setTotalItems(sortedData.length);

      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const subset = sortedData.slice(startIndex, endIndex);

      setItems(subset);
      setIsLoaded(true);
    } catch (error) {

    }
  };

  const getCurrentMonth = async (arg) => {
    try {
      const startDate = arg.view.currentStart;
      const response = await AxiosInstance.post('clinic/availability', { clinic_id: editData.clinic_id, schedule_date: Moment(startDate).format("YYYY-MM") })
      const obj = Object.entries(response.data.data.events)
      obj.forEach(([key, value]) =>
        availabilityData.push({
          start: new Date(value),
          title: '',
          url: product_img,
        })
      )
      setEvents(availabilityData);
    } catch (error) {

    }
  }


  const handleEdit = async (appointment_status, schedule_date) => {
    try {
      const response = await AxiosInstance.post('appointment/edit', { id: editData.id, appointment_status, schedule_date })
      if (response.data.success) {
        setCurrentPage(1)
        setEditData('')
        setThankyou(true)
      }
    } catch (error) {

    }
  }

  const handleEventclick = (clickInfo) => {
    const schedule_date = Moment(clickInfo.event._instance.range.start).format('YYYY-MM-DD');
    setReshedulepop(false)
    handleEdit(4, schedule_date);
  }


  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>
          <DashboardTitle />
          <div className='white_box account-blocks'>
            <Row className='box-title v-center'>
              <Col xs={12} md={6} lg={6} className='left-block'>
                <h4>All Appointments</h4>
              </Col>
            </Row>
            <Row className='box-title v-center'>
              <Col xs={12} md={12} lg={12} className='left-block'>
                <div className='account-table-block'>
                  <Table className='w-100' bsPrefix borderless hover>
                    <thead>
                      <tr>
                        <th width='110'>Ref.</th>
                        <th width='200'>Consultant</th>
                        <th width='136'>Date</th>
                        <th width='110'>Time</th>
                        <th width='140'>Clinic</th>
                        <th width='140'>Appointment type</th>
                        <th width='140'>Payment Status</th>
                        <th width='140'>Amount</th>
                        <th width='100'>Status</th>
                        <th width='100'>Action</th>
                        <th width='100' className='pl-0'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {(Object.values(items).length > 0 ? (
                        Object.values(items)?.map((data, idx) => (
                          <tr key={idx}>
                            <td>{data.id}</td>
                            <td>
                              <div className='consultant-prof d-flex v-center'>
                                <i className='imgbox'>
                                  <img src={review_img2} alt='' />
                                </i>
                                <span>Dr. Pradeep Sethi</span>
                              </div>
                            </td>
                            <td>{data.date}</td>
                            <td>{data.schedule_from_time !== null ? data.schedule_from_time : " - "}</td>
                            <td>{data.name}</td>

                            <td>{data.type_id === 1 ? (
                                "Appointment"
                            ) : data.type_id === 2 ? (
                                "Request a Prescription"
                            ) : data.type_id === 4 ? (
                                    "Booked Surgery Slot"
                                ) :
                                (
                                    "Appointment"
                                )
                            }</td>
                            <td>{data.paid_status === 1 ? (
                                    "Paid"
                                ) :
                                (
                                    "Unpaid"
                                )
                            }</td>
                            <td>{data.paid_status === 1 ? (
                                    data.amount
                                ) :
                                (
                                    "-"
                                )}</td>
                            <td>
                              {data.appointment_status === 1 ? (
                                <span className='btn small-btn postponed'>Pending</span>
                              ) : data.appointment_status === 2 ? (
                                <span className='btn small-btn completed'>Completed</span>
                              ) : data.appointment_status === 3 ? (
                                <span className='btn small-btn canceled'>Cancelled</span>
                              ) :
                                (
                                  <span className='btn small-btn reschedule'>Reschedule</span>
                                )
                              }
                            </td>
                            <td >
                              {(data.appointment_status === 1 || data.appointment_status === 4) && new Date(data.schedule_date) > Date.now() &&

                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}
                                >
                                  <button className='btn small-btn red-btn' onClick={() => { setEditpop(true); setEditData(data) }}>
                                    <i className="las la-edit"></i>
                                  </button>
                                </OverlayTrigger>
                              }
                            </td>
                            <td className='pl-0'>
                              <Link className='btn small-btn red-btn' target='_blank'>
                                <div className='download_icon' ></div> Invoice
                              </Link>
                            </td>
                          </tr>
                        ))) :
                        <tr>
                          <td colSpan='7' className='text-lg-center'>
                            {isLoaded}
                            No Data Found.
                          </td>
                        </tr>)}
                    </tbody>
                  </Table>
                </div>

                {totalItems > 10 ?
                  <div className='pagination-block ptb-20 d-flex v-center'>
                    <div className='result-text'>
                      <p>{`Showing ${currentPage * itemsPerPage - itemsPerPage + 1} - ${Math.min(
                        currentPage * itemsPerPage,
                        totalItems
                      )} items out of ${totalItems} results found`}</p>
                    </div>
                    <Pagination className='ml-auto'>
                      <PaginationControl
                        page={currentPage}
                        between={3}
                        total={totalItems}
                        limit={10}
                        last={true}
                        changePage={(page) => {
                          setCurrentPage(page)
                        }}
                        ellipsis={1}
                      />
                    </Pagination>
                  </div>
                  : ""}
              </Col>
              <Col xs={12} md={12} lg={4} className='right-block mt-3'>
                 {/*Chat Icon*/}
                <div className="chat-icon" onClick={() => setIsChatOpen(!isChatOpen)}>
                  <i className="fa fa-comments" aria-hidden="true"></i>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {/* Chatbox Overlay */}
      {isChatOpen && (
          <div className="chat-overlay">
            <div className="chat-box">
              <div className="chat-header">
                <h4>Chat</h4>
                <button onClick={() => setIsChatOpen(false)}>Close</button>
              </div>
              <ChatBlock showHeader={showHeader} msgCategoryId="3" />
            </div>
          </div>
      )}
      <Modal id="modalPrescription" show={editpop} onHide={() => handleClose()} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className='appoint-booking-body ptb-20 pb-0 text-center'>
            <p className='text-20'>Are you sure you want to cancel or reschedule appointment ?</p>

            <div className='btnbox ptb-20 pb-0 d-flex'>
              <div className='btn-items'>
                <Button className='btn primary' onClick={() => { setReshedulepop(true); setEditpop(false) }}>Reschedule</Button>
              </div>
              <div className='btn-items'>
                <Link className='btn secondary' onClick={() => { handleClose(); handleEdit(3, editData.schedule_date) }} to=''>Cancel</Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal id="modalPrescription" show={reschedulepop} onHide={() => handleClose()} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className='calendar-block'>
            <div className='white_box card-calendar'>
              <div className="calendar_style">
                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView='dayGridMonth'
                  datesSet={(arg) => getCurrentMonth(arg)}
                  events={events}
                  eventClick={handleEventclick}
                  editable={true}
                  eventContent={renderEventContent}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={thankyou} onHide={() => handleClose()} size="lg" className='thank-you-modal' centered>
        <Modal.Header closeButton className='p-0 border-0'>
        </Modal.Header>
        <div className='thankyou-page'>
          <div className='white_box thankyou-inn'>
            <div className='thankyou-innbox'>

              <div className='block-title text-center'>
                <i className='thankyou-iconbox d-flex'>
                  <img src={thankyou_icon} alt='' /></i>
                <h1 className='h2'>Thank You</h1>
                <p>One of our representative will contact you soon.</p>
              </div>

            </div>
          </div>
        </div>

      </Modal>
    </div>
  );
}

export default MyAccount;
