import React, { useState, useEffect } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Pagination, Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap';
import review_img2 from '../../assets/images/dr.png';
import configData from "../../config.json";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import useAxiosInstance from '../../Api/AxiosInstance';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import Moment from 'moment';
import product_img from '../../assets/images/dr.png';
import thankyou_icon from '../../assets/images/thankyou_icon.svg';
import ChatBlock from './dashboard_modules/ChatBlock';

function renderEventContent(eventInfo) {
    return (
        <>
            <div className='avail_app'>
                <img src={eventInfo.event.url} width="25px" /><br></br>
                <i>{eventInfo.event.title}</i>
            </div>
        </>
    )
}

function MyAccount() {

    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(configData.DATA_PER_PAGE);
    const [totalItems, setTotalItems] = useState(0);
    const AxiosInstance = useAxiosInstance()
    const [editpop, setEditpop] = useState(false)
    const [reschedulepop, setReshedulepop] = useState(false)
    const handleClose = () => { setEditpop(false); setReshedulepop(false); setThankyou(false); setEditData('') }
    const availabilityData = [];
    const [events, setEvents] = useState([]);
    const [editData, setEditData] = useState('')
    const [thankyou, setThankyou] = useState(false)
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [showHeader, setShowHeader] = useState(false);



    useEffect(() => {
        fetchAppointments();
    }, [currentPage, thankyou]);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Reschedule / Cancel
        </Tooltip>
    );

    const fetchAppointments = async () => {
        try {
            const response = await AxiosInstance.post('my-appointments')
            const allData = Object.values(response.data.data);
            const sortedData = allData.sort((a, b) => new Date(b.schedule_date) - new Date(a.schedule_date));
            setTotalItems(sortedData.length);

            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const subset = sortedData.slice(startIndex, endIndex);

            setItems(subset);
            setIsLoaded(true);
        } catch (error) {

        }
    };

    const getCurrentMonth = async (arg) => {
        try {
            const startDate = arg.view.currentStart;
            const response = await AxiosInstance.post('clinic/availability', { clinic_id: editData.clinic_id, schedule_date: Moment(startDate).format("YYYY-MM") })
            const obj = Object.entries(response.data.data.events)
            obj.forEach(([key, value]) =>
                availabilityData.push({
                    start: new Date(value),
                    title: '',
                    url: product_img,
                })
            )
            setEvents(availabilityData);
        } catch (error) {

        }
    }


    const handleEdit = async (appointment_status, schedule_date) => {
        try {
            const response = await AxiosInstance.post('appointment/edit', { id: editData.id, appointment_status, schedule_date })
            if (response.data.success) {
                setCurrentPage(1)
                setEditData('')
                setThankyou(true)
            }
        } catch (error) {

        }
    }

    const handleEventclick = (clickInfo) => {
        const schedule_date = Moment(clickInfo.event._instance.range.start).format('YYYY-MM-DD');
        setReshedulepop(false)
        handleEdit(4, schedule_date);
    }


    return (
        <div className='main-page bg4'>
            <DashboardHeader />
            <DashboardSidebar />

            <section className='main-section'>
                <div className='main-inner'>
                    <DashboardTitle />
                    <div className='white_box account-blocks'>
                        <Row className='box-title v-center'>
                            <Col xs={12} md={6} lg={6} className='left-block'>
                                <h4>Eugenix EMI Disclaimer*</h4>
                            </Col>
                        </Row>
                        <Row className='box-title v-center'>
                            <Col xs={12} md={12} lg={12} className='left-block'>
                                <div className='account-table-block'>
                                    <p>
                                        Eugenix Hair Sciences merely acts as an intermediary between you and the Finance Company. We share no professional association
                                        with the Finance Company that will approve, authorize and sanction your Loan. It is the responsibility of the patient to complete
                                        all formalities, paperwork and approvals between them and the finance , and the E-Mandate is processed at least 4 days prior to
                                        the Date of Procedure at Eugenix. Eugenix reserves the right to hold the procedure for postponement at the next suitable date in
                                        that cases where all formalities for EMI including E-Mandate are not fulfilled and completed, hence would further humbly request
                                        you to complete all the formalities including E mandate at least 4 days prior to the Date of Procedure.
                                    </p>
                                    <p>
                                        Please note that if the surgery is delayed by more than 20 days from the date of loan
                                        approval, the approved loan may be void and the patient has to initiate the loan process
                                        again. Subvention charges, in accordance with the specified plan, will be deducted in
                                        instances where a refund is requested, and the option of EMI (Equated Monthly Instalments)
                                        is chosen. This deduction is subject to the terms and conditions outlined in the agreement.
                                        We recommend reviewing the terms carefully before proceeding with any refund requests.
                                    </p>
                                    <p>
                                        If the total graft count is lower than the estimated amount at the time of the procedure,
                                        a refund will be issued for the difference, subject to a deduction of a 7.25% subvention
                                        charge.
                                    </p>
                                </div>

                            </Col>

                        </Row>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default MyAccount;
