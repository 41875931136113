import React, { useState, useCallback, useEffect } from 'react';
import DashboardHeader from '../include/DashboardHeader';
import DashboardSidebar from '../include/DashboardSidebar';
import DashboardTitle from './dashboard_modules/DashboardTitle';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import { useDispatch } from "react-redux";
import product_img from '../../assets/images/dr.png';
import Moment from 'moment';
import { logout } from "../../actions/auth";
import useRazorpay from "react-razorpay";
import Cookies from 'js-cookie';
import useAxiosInstance from '../../Api/AxiosInstance';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';


function renderEventContent(eventInfo) {
  return (
    <>
      <div className='avail_app'>
        <img src={eventInfo.event.url} width="25px" /><br></br>
        <i>{eventInfo.event.title}</i>
      </div>
    </>
  )
}

function DateTime() {
  const clinic_id = JSON.parse(localStorage.getItem("clinic_id"));
  const booking_type = localStorage.getItem("booking_type");

  const type_id = clinic_id === 16 ? 2 : booking_type === "surgery" ? 4: 1;

  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  let navigate = useNavigate();

  const displayData = [];
  const availabilityData = [];
  const [slots, setSlots] = useState('');
  const [events, setEvents] = useState([]);
  const [schedule_date, setDate] = useState('');
  const [schedule_from_time, setStartTime] = useState('');
  const [schedule_to_time, setEndTime] = useState('');
  const AxiosInstance = useAxiosInstance()
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [time, setTime] = useState('10:00');


  const handleEventClick = async (clickInfo) => {
    try {
      if (type_id === 4) {
        setShowModal(true);
        setShow(false);
      }else{
        setShow(true);
      }
      const date = Moment(clickInfo.event._instance.range.start).format('YYYY-MM-DD');
      setDate(date);

      const response = await AxiosInstance.post('clinic/availability', { clinic_id: clinic_id, schedule_date: date })
      const obj = Object.entries(response.data.data.events)
      obj.forEach(([key, value]) =>
        displayData.push(<label onClick={() => { setScheduleTime(value.slot_start_time, value.slot_end_time); }} className='radiobox time-radio'><Form.Control type='radio' name="Time" value={value.slot_start_time} /><span className='checkmark'></span>{value.slot_start_time}</label>)
      )
      const responseCharges = await AxiosInstance.post("clinic/charges", { clinic_id: clinic_id, type_id: type_id })
      setCharges(responseCharges.data.data)
      setSlots(displayData);
    } catch (error) {

    }
  }

  function setScheduleTime(start_time, end_time) {
    setStartTime(start_time);
    setEndTime(end_time);
    handleShow2();
  }

  const bookAnAppointment = async () => {
    try {
      const response = await AxiosInstance.post("appointment/add", { clinic_id, schedule_date, type_id, time })
      if (response.data.status == 200) {
        navigate("/thankyou", { state: response.data.data.appointment },);
        window.location.reload();
      }
    } catch (error) {

    }
  }

  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); setChargerror(false) };
  const [razorpay, isLoaded] = useRazorpay();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  let [orderId, setOrderId] = useState('');
  const [appointment_id, setAppointment_id] = useState('')
  const [show3, setShow3] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null); // State to store the selected charge
  const [chargerror, setChargerror] = useState(false);
  const [cahrges, setCharges] = useState([])
  const [options, setOptions] = useState(
    {
      key: 'rzp_test_3PYKIWLbUWhl67',
      accept_partial: false,
      amount: Math.round(selectedCharge * 100),
      currency: "INR",
      name: "Eugenix",
      description: "Test Transaction",
      image: "http://localhost/euginix_crm_backend/assets/front/images/logo.png",
      order_id: orderId,
      handler: async (res) => {
        if (res.razorpay_payment_id) {
          try {
            const checkedorderId = Cookies.get('checkedorderId');
            const response = await AxiosInstance.post('add-payment-details', { table_id: checkedorderId, type_id: 1, payment_id: res.razorpay_payment_id, amount: selectedCharge })
            if (response.data.status == 200) {
              navigate('/appointments')
              window.location.reload()
            }

          } catch (error) {

          }
        }
      },
      notes: {
        permanent_address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    }
  )
  const handleChargeChange = (charge) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      amount: charge * 100,
      handler: async (res) => {
        if (res.razorpay_payment_id) {
          try {
            const checkedorderId = Cookies.get('checkedorderId');
            const response = await AxiosInstance.post('add-payment-details', { table_id: checkedorderId, type_id: 1, payment_id: res.razorpay_payment_id, amount: charge })
            if (response.data.status == 200) {
              navigate('/appointments')
              window.location.reload()
            }

          } catch (error) {

          }
        }
      },
    }));
    setSelectedCharge(charge);
    setChargerror(false); // Reset error message when a charge is selected
  };
  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };



  const handleShow3 = async () => {
    if (!selectedCharge) {
      setChargerror(true)
    }
    else {
      setShow(false)

      try {
        const orderDetailsResponse = await AxiosInstance.post("appointment/add", { clinic_id, schedule_date, type_id, time });
        const checkedorderId = orderDetailsResponse.data.data.appointment.id;
        Cookies.set('checkedorderId', checkedorderId);
        setAppointment_id(checkedorderId);

        if (selectedCharge !== 1) {
          const response = await AxiosInstance.post("create-order-id-in-razorpay", { amount: selectedCharge });

          if (response.status === 400 && response.data.message === "Token is expired!!") {
            logOut();
            navigate("/login");
          } else if (response.status === 200) {
            setSelectedCharge(null);
            const orderId = response.data.data.order_id;
            const amount = response.data.data.amount;

            const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY_ID,
              amount: Math.round(selectedCharge * 100),
              currency: "INR",
              name: "Eugenix",
              description: "Test Transaction",
              image: "http://localhost/euginix_crm_backend/assets/front/images/logo.png",
              order_id: orderId,
              handler: async (res) => {
                if (res.razorpay_payment_id) {
                  try {
                    const checkedorderId = Cookies.get('checkedorderId');
                    const paymentResponse = await AxiosInstance.post("add-payment-details", {
                      table_id: checkedorderId,
                      type_id: 1,
                      payment_id: res.razorpay_payment_id,
                      amount: selectedCharge,
                    });

                    if (paymentResponse.data.status === 200) {
                      navigate('/appointments', { replace: true });
                    }
                  } catch (error) {
                    console.error("Error while adding payment details:", error);
                  }
                }
              },
              notes: {
                permanent_address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
            };

            const rzpay = new razorpay(options);
            rzpay.open();
          }
        } else {
          console.log(selectedCharge);
          navigate("/thankyou", { state: orderDetailsResponse.data.data.appointment });
        }
      } catch (error) {
        console.error("Error in Razorpay integration:", error);
        alert("Something went wrong. Please try again.");
      }


    }
  }


  useEffect(() => {
    const handleBookAppointmentClick = async () => {
      try {
        const response = await AxiosInstance.post("clinic/charges", { clinic_id: clinic_id, type_id: type_id })
        setCharges(response.data.data)
      } catch (error) {
        console.log(error)
      }
    };
    handleBookAppointmentClick()
  }, [])

  const getCurrentMonth = async (arg) => {
    try {
      const startDate = arg.view.currentStart;
      const response = await AxiosInstance.post('clinic/availability', { clinic_id: clinic_id, schedule_date: Moment(startDate).format("YYYY-MM") })
      const obj = Object.entries(response.data.data.events)
      obj.forEach(([key, value]) =>
        availabilityData.push({
          start: new Date(value),
          title: '',
          url: product_img,
        })
      )
      setEvents(availabilityData);
    } catch (error) {

    }


  }

  return (
    <div className='main-page bg4'>
      <DashboardHeader />
      <DashboardSidebar />

      <section className='main-section'>
        <div className='main-inner'>
        
          <DashboardTitle />

          <div className='provider-page-main'>

            <div className='box-title d-flex v-center'>
              <h3 className='mb-0'>Select a date for Appointment</h3>

              <div className='breadcrumb-block ml-auto'>
                <ul className='breadcrumb-list d-flex v-center'>
                  <li><Link to='/dashboard'>Home</Link></li>
                  <li><Link to='/location'>Location</Link></li>
                  <li>Date & Time</li>
                </ul>
              </div>
            </div>

            <div className='calendar-block'>
              <div className='white_box card-calendar'>
                <div className="calendar_style">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView='dayGridMonth'
                    datesSet={(arg) => getCurrentMonth(arg)}
                    events={events}
                    eventClick={handleEventClick}
                    editable={true}
                    eventContent={renderEventContent}
                  />
                </div>
                {/* Modal for Time Picker */}
                <Modal show={show} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Select Time for Appointment</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                        id="time"
                        type="time"
                        value={time}
                        onChange={handleTimeChange}
                        style={{
                          padding: "8px",
                          fontSize: "16px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        }}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn primary mw_200"  onClick={() => setShowModal(true)}> Add Appointment </button>
                  </Modal.Footer>
                </Modal>
                <Modal show={showModal} onHide={() => setShowModal(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Body>
                    <Modal.Title>Choose the option:</Modal.Title>
                    {
                      cahrges?.map((charge) => (
                        <>
                          <label className='radiobox time-radio mt-2' >
                            <Form.Control
                                type="radio"
                                name="Time"
                                value={charge.amount !== null ? charge.amount : 1} // Default to 1 if charge.amount is null
                                checked={selectedCharge === (charge.amount !== null ? charge.amount : 1)} // Compare against the defaulted value
                                onChange={() => setSelectedCharge(charge.amount !== null ? charge.amount : 1)} // Handle selection
                            />
                            <span className='checkmark'></span>{charge.charge_name}  {charge.amount !== null ? ` ₹${charge.amount}` : ''}
                          </label>
                          <br />
                        </>
                      ))
                    }
                    {chargerror && <p className="text-danger mt-3">Please select any option.</p>}
                    <div className='btnbox d-flex option-btn'>
                      <div className='btn-items'>
                        {clinic_id !== 16 &&  booking_type !== 'surgery' && (
                            <Link className="btn primary mw_200" onClick={() => bookAnAppointment()}>
                              Confirm Registration
                            </Link>
                        )}

                      </div>
                      <div className='btn-items' onClick={handleClose2}>
                        <button className='btn secondary mw_200' onClick={handleShow3} >Pay Registration fee</button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}
export default DateTime

