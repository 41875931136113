import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Login from './components/pages/Login';
import Register from './components/pages/Register';
import ForgotPassword from './components/pages/ForgotPassword';
import Dashboard from './components/pages/Dashboard';
import MyProfile from './components/pages/MyProfile';
import EditProfile from './components/pages/EditProfile';
import ShopPage from './components/pages/ShopPage';
import Location from './components/pages/Location';
import DateTime from './components/pages/DateTime';
import ThankYou from './components/pages/ThankYou';
import ApplyEMI from './components/pages/ApplyEMI';
import EMITerms from './components/pages/EMITerms';
import ThankYouEMI from './components/pages/ThankYouEMI';
import Cart from './components/pages/Cart';
import Checkout from './components/pages/Checkout';
import ThankYouOrder from './components/pages/ThankYouOrder';
import Appointments from './components/pages/Appointments';
import MyOrder from './components/pages/MyOrder';
import MyInvoices from './components/pages/MyInvoices';
import MyPrescription from './components/pages/MyPrescription';
import MyPhotos from './components/pages/MyPhotos';
import MyNotification from './components/pages/MyNotification';
import EMIOptions from './components/pages/EMIOptions';
import ChangePassword from './components/pages/ChangePassword';
import Review from './components/pages/Review';
import { AuthProvider } from './Context/AuthContext';


function App() {
  return (

    <>
      <AuthProvider>
        <Routes>
          <Route>
            <Route index element={<Home />} />
            <Route path='home' exact={true} element={<Home />} />
            <Route path='about' exact={true} element={<About />} />
            <Route path='contact' exact={true} element={<Contact />} />
            <Route path='login' exact={true} element={<Login />} />
            <Route path='register' exact={true} element={<Register />} />
            <Route path='forgot-password' exact={true} element={<ForgotPassword />} />
            <Route path='dashboard' exact={true} element={<Dashboard />} />
            <Route path='my-profile' exact={true} element={<MyProfile />} />
            <Route path='edit-profile' exact={true} element={<EditProfile />} />
            <Route path='change-password' exact={true} element={<ChangePassword />} />
            <Route path='shop' exact={true} element={<ShopPage />} />
            <Route path='location' exact={true} element={<Location />} />
            <Route path='date-time' exact={true} element={<DateTime />} />
            <Route path='thankyou' exact={true} element={<ThankYou />} />
            <Route path='apply-emi' exact={true} element={<ApplyEMI />} />
            <Route path='emi-terms' exact={true} element={<EMITerms />} />
            <Route path='emi-thankyou' exact={true} element={<ThankYouEMI />} />
            <Route path='cart' exact={true} element={<Cart />} />
            <Route path='checkout' exact={true} element={<Checkout />} />
            <Route path='order-thankyou' exact={true} element={<ThankYouOrder />} />
            <Route path='appointments' exact={true} element={<Appointments />} />
            <Route path='myorder' exact={true} element={<MyOrder />} />
            <Route path='notifications' exact={true} element={<MyNotification />} />
            <Route path='myinvoices' exact={true} element={<MyInvoices />} />
            <Route path='myprescriptions' exact={true} element={<MyPrescription />} />
            <Route path='myphotos' exact={true} element={<MyPhotos />} />
            <Route path='emioptions' exact={true} element={<EMIOptions />} />
            <Route path='review' exact={true} element={<Review />} />
          </Route>
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;