import {SET_MESSAGE, CLEAR_MESSAGE, CART_COUNT} from "../actions/types";

const initialState = {
    cartCount:0
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CART_COUNT:
            return { cartCount: state.cartCount + 1 };

        default:
            return state;
    }
}