import axios from 'axios';
import { getToken } from '../Context/AuthContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; // Corrected import path

const useAxiosInstance = () => {
    const navigate = useNavigate();
    const access_token = getToken();

    const AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
        }
    });

    AxiosInstance.interceptors.request.use((config) => {
        return config;
    });

    // Response interceptor
    AxiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            console.log(error);
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message;
                if (errorMessage === "Unauthenticated.") {
                    localStorage.removeItem('user');
                    navigate('/login');
                } else if (error.message === "Network Error") {
                    toast.error(error.message, {
                        autoClose: 3000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else if (errorMessage === "Unauthorized") {
                    toast.error("Invalid username or password.", {
                        autoClose: 3000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else if (errorMessage === "Validation fails.") {
                    toast.error("Enter correct details.", {
                        autoClose: 3000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else if (errorMessage === "Please enter correct old password.") {
                    toast.error("The current password entered is incorrect.", {
                        autoClose: 3000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else if (error.response.data.message === "Token is expired!!") {
                    localStorage.removeItem('user');
                    navigate('/login');
                } else {
                    toast.error("An unexpected error occurred. Please try again later.", {
                        autoClose: 3000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position:'top-right'
                    });
                }
            }
            return Promise.reject(error);
        }
    );

    return AxiosInstance;
};

export default useAxiosInstance;
