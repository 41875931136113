import React ,{useEffect , useRef, useState} from 'react';
import {  Form, Button } from 'react-bootstrap'
import user_img from '../../../assets/images/user.png';
import io from 'socket.io-client';
import axios from 'axios';
import useAxiosInstance from '../../../Api/AxiosInstance';

const socket = io('https://io.eugenixhairsciences.com/');

const  ChatBlock = ({ showHeader  = false, msgCategoryId = 1 }) => {
  const [message, setMessage] = useState('');

  const [messages, setMessages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState('');
  const user = JSON.parse(localStorage.getItem("user"));
  const subUser = 'chat_room_' + user?.user?.id + '_user';
  const chatEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const config = {
    headers: { Authorization: `Bearer ${user.access_token}` }
  };
  const AxiosInstance = useAxiosInstance()
  const allowedTypes = [
    'image/jpeg', 'image/png', 'image/gif', 'application/pdf',
    'application/vnd.ms-powerpoint', 'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/zip', 'application/x-zip-compressed',
    'video/mp4', 'video/mpeg', 'video/quicktime'
  ];
  const maxFileSize = 10 * 1024 * 1024; // 10 MB

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await AxiosInstance.post( 'fetch-messages', {msgCategoryId : msgCategoryId})
      setMessages(response.data.data.userChats);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchMessages();
      socket.emit('subscribe', subUser);

      socket.on('connect', () => {
        console.log('Connected to the server');
      });

      socket.on('disconnect', () => {
        console.log('Disconnected from server');
      });

      // Function to handle incoming messages
      const handleChatMessage = (data) => {
        console.log('Message receiving...');
        setMessages((prevMessages) => [...prevMessages, data[0]]);
        scrollToBottom();
      };

      // Attach the event listener
      socket.on('chat', handleChatMessage);

      return () => {
        socket.off('chat', handleChatMessage);
      };
    }

  }, []);

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!allowedTypes.includes(file.type)) {
        setError('Invalid file type. Please select a valid file.');
        setSelectedFile(null);
        setMessage('');
        return;
      }
      if (file.size > maxFileSize) {
        setError('File size exceeds the limit of 10 MB. Please select a smaller file.');
        setSelectedFile(null);
        setMessage('');
        return;
      }
      setSelectedFile(file);
      setMessage(file.name);
      setError('');
    } else {
      setSelectedFile(null);
      setMessage('');
      setError('');
    }
  };

  const handleInputChange = (event) => {
    if (!selectedFile) {
      setMessage(event.target.value);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    let isFile = false;

    if (!message.trim() && !selectedFile) return;

    try {
      // New code starts
      const formData = new FormData();
      formData.append('sender_id', user.user.id);
      formData.append('senderName', user.user.full_name);
      formData.append('message', message);
      if (window.location.pathname.includes('myorder')) {
        formData.append('msg_category_id', 2);
      }if (window.location.pathname.includes('appointments')) {
        formData.append('msg_category_id', 3);
      }
      if (selectedFile) {
        isFile = true;
        formData.append('upload', selectedFile);
      }
      // New code ends

      const response = await AxiosInstance.post( "send-message", formData)
        .then(response => {
          let chatMessageHtml = isFile ? response.data.data.messageFile : message.trim();
          const responseData = {
            'message': chatMessageHtml,
            'senderName': user?.user.full_name,
            'sender_id': user?.user.id,
            'sender_role_id': 0,
            'recipient_id': '',
            'recipientName': '',
            'sender_image': '',
            'formatted_created_at': formatTime(new Date()),
            'room_name': subUser,
            'bitrix_lead_id': user?.user.bitrix_lead_id,
          };

          const responseDataArray = [responseData];

          // Check if socket is connected
          if (socket && socket.connected) {
            console.log("Socket is connected, emitting data...");
            socket.emit('send', { room: subUser, data: responseDataArray });

            const responseSuperAdminData = {
              'senderName': user?.user.full_name,
              'sender_id': user?.user.id,
              'sender_role_id': 0,
              'room_name': 'SUPERADMIN'
            };
            const responseSuperAdminDataArray = [responseSuperAdminData];

            socket.emit('send', { room: 'SUPERADMIN', data: responseSuperAdminDataArray });
            console.log("Data emitted successfully");
          } else {
            console.error("Socket is not connected");
          }
        })
        .catch(error => {
          console.error('Error fetching states:', error);
        });
      setMessage('');
      setSelectedFile(null);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <Form className='chat-form' onSubmit={sendMessage}>
      <div className='white_box chat-wrapper'>
        {showHeader && (
            <div className="box-title mb-0 d-flex v-center">
              <h4>Chat</h4>
            </div>
        )}


        <div className='chat-wrap-middle d-flex'>
          <div className='chat-wrap-inn relative w-100 h-100'>
            <div className='chating-block ptb-20'>
              {messages.map((msg, index) => {
                const isCurrentUser = msg.senderName === user?.user.full_name;
                const msgClass = isCurrentUser ? 'my-msg-items' : '';
                return (
                  <div key={index} className={`msg-items d-flex ${msgClass}`}>
                    <div className='msg-time'>{msg.senderName} {msg.formatted_created_at}</div>
                    {!isCurrentUser && (
                      <i className='chat-imgbox'>
                        <img src={msg.sender_image} alt='' />
                      </i>
                    )}
                    <div className='chat-text-list'>
                      <div className='chat-text' dangerouslySetInnerHTML={{ __html: msg.message }} />
                    </div>
                  </div>
                );
              })}
              <div ref={chatEndRef} />
            </div>

            {/* <small className='typing-text'>Essie Howell is typing...</small> */}

          </div>
        </div>

        <div className='chat-wrap-bottom d-flex v-center relative'>
          <div className='chat-btnbox d-flex w-100'>
            <Form.Control
              className='chat-input'
              type='text'
              placeholder='Type a message...'
              value={message}
              onChange={handleInputChange}
              readOnly={!!selectedFile}
            />
            <span className='btn chat-upload-btn' onClick={() => fileInputRef.current.click()}>
              <i className="las la-file-alt"></i>
            </span>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <Button className='btn chat-btn' type="submit">
              <i className="las la-paper-plane"></i>
            </Button>
          </div>
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    </Form>
  );
  // Function to format time in "hh:mm am/pm" format
  function formatTime(timestamp) {
    var time = new Date(timestamp);
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var formattedTime = hours + ':' + minutes + ' ' + ampm;
    return formattedTime;
  }

}

export default ChatBlock;