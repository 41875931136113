import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import logo from '../../assets/images/site-logo.png';
import { logout } from "../../actions/auth";
import icon_whatsapp from '../../assets/images/icon_whatsapp.svg';
import PrescriptionModal from './PrescriptionModal';
import { useDispatch } from "react-redux";
import useAxiosInstance from '../../Api/AxiosInstance';
import BookSurgury from "../pages/BookSurgury";

function DashboardHeader() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [items, setItems] = useState()
    const [messages, setMessages] = useState()
    const AxiosInstance = useAxiosInstance()


    const user = JSON.parse(localStorage.getItem("user"));
    const handleLogout = (e) => {
        dispatch(logout());
        navigate("/login");
    }

    const fetchData = async () => {

        try {
            const response = await AxiosInstance.post('cart-lists')
            setItems(response.data.data.length);
        } catch (error) {

        }
    }
    const fetchDataMessage = async () => {

        try {
            const responseNotification = await AxiosInstance.post( 'get-count-notifications')
            setMessages(responseNotification.data.data.notifications.length);
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchData();
        document.addEventListener('itemAddedToCart', fetchData);
        return () => {
            document.removeEventListener('itemAddedToCart', fetchData);
        };
    }, [fetchData])
    useEffect(() => {
        fetchDataMessage();
    }, [ fetchDataMessage])

    return (
        <>
            <header className='header dashboard-header'>
                <div className='dash-header-inner d-flex v-center'>

                    <div className='site-logo dashboard-logo d-flex v-center h-100'>
                        <Navbar.Brand>
                            <Link to='/dashboard'><img src={logo} alt='' /></Link>
                        </Navbar.Brand>
                    </div>

                    <div className='dash-head-right d-flex v-center'>
                        <div className='head-title-box'>
                            <h4 className='mb-0'><Link to='/dashboard'>My Dashboard</Link></h4>
                        </div>

                        <div className='dash-head-right-inn ml-auto d-flex v-center'>
                            <div className='dash-head-right-btnbox'>

                            </div>

                            <div className='dash-head-iconbox d-flex v-center'>

                                <Link className='head-icon-link' to='/cart/'>
                                    <i className='fa fa-shopping-cart'></i>
                                    <span className='cart-count'>{items}</span>
                                </Link>
                                <Link className='head-icon-link whatsapp_icon'
                                    // to='whatsapp://send?phone=91-9824928965'
                                    to='/notifications'
                                    target='_blank'>
                                    <i class="fa fa-bell"></i>
                                    <span className='cart-count'>{messages}</span>
                                </Link>
                                <Dropdown className='head-profile'>
                                    <Dropdown.Toggle className='profile-link d-flex v-center' id="dropdown-profile">
                                        <span className='head-icon-link'><img src={user?.user?.image_url} alt='' /></span>
                                        <strong className='profile-name'>{user?.user?.full_name}</strong>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="/my-profile">My Profile</Dropdown.Item>
                                        <Dropdown.Item href="/edit-profile">Edit Profile</Dropdown.Item>
                                        <Dropdown.Item href="/change-password">Change Password</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleLogout()} >Log Out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default DashboardHeader