import axios from "axios";


const register = (full_name, email_id,mobile_no, password, gender) => {
  return axios.post(process.env.REACT_APP_API_URL + "signup", {
      full_name,
    email_id,
    mobile_no,
    password,
    gender
  }).then((response) => {
      if (response.data.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
  });
};

const login = (mobile_no, password, otp = '123456') => {
  return axios
    .post(process.env.REACT_APP_API_URL + "login", {
      mobile_no,
      password,
      otp,
    })
    .then((response) => {
   
      if (response.data.data.access_token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));

      }

      return response.data.data;
    });
};

const google_login = (google_id, full_name, email_id) => {
  return axios
      .post(process.env.REACT_APP_API_URL + "social-login", {
        google_id,
        full_name,
        email_id
      })
      .then((response) => {

        if (response.data.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        }

        return response.data.data;
      });
};
const facebook_login = (facebook_id, full_name, email_id) => {
  return axios
      .post(process.env.REACT_APP_API_URL + "social-login", {
        facebook_id,
        full_name,
        email_id
      })
      .then((response) => {

        if (response.data.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        }

        return response.data.data;
      });
};

const forgot_password = (mobile_no, email) => {
  return axios
      .post(process.env.REACT_APP_API_URL + "forgot-password", {
          mobile_no,
          email
      })
      .then((response) => {
        if (response.data.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        }

        return response.data.data;
      });
};


const logout = () => {
  localStorage.removeItem("user");
};

export default {
  register,
  login,
  logout,
  google_login,
  facebook_login,
  forgot_password
};
