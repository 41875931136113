import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Offcanvas } from 'react-bootstrap';
import logo from '../../assets/images/site-logo.png';
import nav_icon_1 from '../../assets/images/icon_appointment.svg';
import nav_icon_2 from '../../assets/images/icon_orders.svg';
import nav_icon_4 from '../../assets/images/icon_emi.svg';
import nav_icon_5 from '../../assets/images/icon_photos.svg';
import nav_icon_6 from '../../assets/images/icon_prescription.svg';
import { useLocation } from "react-router-dom";

function DashboardSidebar(props) {

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const user = JSON.parse(localStorage.getItem("user"));
    return (
        <>
            <div className='dashboard-sidebar'>
                {['xl'].map((expand) => (
                    <Navbar key={expand} expand={expand}  >
                        <Navbar.Offcanvas className='mobi-nav-block' id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement='start'>

                            <Offcanvas.Header closeButton className='mobile-header'>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    <div className='site-logo'>
                                        <Navbar.Brand>
                                            <Link to='/home'>
                                                <img src={logo} alt='' />
                                            </Link>
                                        </Navbar.Brand>
                                    </div>
                                </Offcanvas.Title>
                            </Offcanvas.Header>

                            <Offcanvas.Body>
                                <div className='sidebar_head-center'>
                                    <nav className='sidebar_menu_head'>
                                        <ul className='sidebar-nav'>
                                            <li className={splitLocation[1] === "appointments" ? "active" : ""}>
                                                <Link className='nav-link' to='/appointments/'><i className='iconbox'><img src={nav_icon_1} alt='' /></i> <span>My Appointment</span></Link>
                                            </li>
                                            <li className={splitLocation[1] === "myorder" ? "active" : ""}>
                                                <Link className='nav-link' to='/myorder/'><i className='iconbox'><img src={nav_icon_2} alt='' /></i> <span>My Orders</span></Link>
                                            </li>
                                            {/* <li className={splitLocation[1] === "myinvoices" ? "active" : ""}>
                                    <Link className='nav-link' to='/myinvoices/'><i className='iconbox'><img src={nav_icon_3} alt='' /></i> <span>My Invoices</span></Link>
                                </li> */}
                                            <li className={splitLocation[1] === "emioptions" ? "active" : ""}>
                                                <Link className='nav-link' to={user?.user?.emi_option_status === 1 ? "/emioptions" : "/apply-emi"}><i className='iconbox'><img src={nav_icon_4} alt='' /></i> <span>EMI Options</span></Link>
                                            </li>
                                            <li className={splitLocation[1] === "myphotos" ? "active" : ""}>
                                                <Link className='nav-link' to='/myphotos/'><i className='iconbox'><img src={nav_icon_5} alt='' /></i> <span>Photos</span></Link>
                                            </li>
                                            <li className={splitLocation[1] === "myprescriptions" ? "active" : ""}>
                                                <Link className='nav-link' to='/myprescriptions/'><i className='iconbox'><img src={nav_icon_6} alt='' /></i> <span>My Prescriptions</span></Link>
                                            </li>
                                            {/*<li>
                                                <Link className='nav-link' to='/review'><i className='iconbox'><img src={nav_icon_1} alt='' /></i> <span>Write Review</span></Link>
                                            </li>*/}
                                        </ul>
                                    </nav>
                                </div>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>

                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

                    </Navbar>
                ))}
            </div>

        </>
    )
}
export default DashboardSidebar
