import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify'
import { useDispatch } from "react-redux";
import useAxiosInstance from '../../Api/AxiosInstance';

const ProductData = () => {
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState('Loading...');
  const AxiosInstance = useAxiosInstance()


  useEffect(() => {
    const fetchMedicine = async () => {
      try {
        const response = await AxiosInstance.post('medicines')
        setItems(response.data.data);
      } catch (error) {

      }
    }
    fetchMedicine()

  }, [])

  const  addToCart = async(id, price, qty, index) => {
    try {
      const formData = new FormData();
      formData.append("medicine_id", id);
      formData.append("price", price);
      formData.append("quantity", qty);
      formData.append("sub_total", price * qty);
   
      if (items[index]?.addToCartText !== 'View Cart') {
       const response = await  AxiosInstance.post("add-to-cart", formData)

            if (response.data.success) {
              if (window.location.pathname === "/cart/") {
                window.location.reload()
              }
              document.dispatchEvent(new Event('itemAddedToCart'));
              toast.success('Item added to cart', {
                position: "top-right",
                autoClose: 1000,
              });
              // Update button text to 'View Cart' for the specific item
              setItems(prevItems => {
                const updatedItems = [...prevItems];
                updatedItems[index].addToCartText = 'View Cart';
                return updatedItems;
              });
            } 
      }
      
    } catch (error) {
      
    }
   
  }

  return (
    <div className='white_box profile-form-block'>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className='box-title'>
        <h3>Order Medicines</h3>
      </div>
      <Row className='product-list'>
        {items?.length > 0 ? items?.map((data, idx) => (
          <Col xs={12} md={3} lg={3} className='items' key={idx}>
            <div className='card-prod d-flex'>
              <div className='pro-imgbox'>
                <img src={data.image} alt='' />
              </div>
              <div className='textbox'>
                <h4>{data.name.length > 30 ? `${data.name.substring(0, 30)}...` : data.name}</h4>
                <p>{data.short_description.length > 50 ? `${data.short_description.substring(0, 30)}...` : data.short_description}</p>
              </div>
              <div className='price-box mt-auto'>
                <strong className='prod-price'>₹{data.price}</strong>
              </div>
              <div className='prod-btnbox'>
                <Link className='btn addtocard_btn' onClick={() => addToCart(data.id, data.price, 1, idx)} to={(items[idx]?.addToCartText === 'View Cart' ? '/cart/' : '')} >{items[idx]?.addToCartText || 'Add to Cart'} <i className="las la-shopping-cart"></i></Link>
              </div>
            </div>
          </Col>
        )) :
          <Col xs={12} md={12} lg={12} className='items'>
            <div className='card-prod d-flex'>
              <p>{isLoaded}</p>
            </div>
          </Col>}
      </Row>
    </div>
  )
}

export default ProductData
