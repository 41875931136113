import React, { useState, useEffect } from 'react'
import {Container, Form, Button} from 'react-bootstrap';
import banner_image from '../../assets/images/banner_img.jpg';
import { Link } from 'react-router-dom';

const Banner = () => {

  return (
    <>
    <section className='home-banner d-flex v-center bg1'>
        <Container className='relative'>
            <div className='banner-caption'>
                <div className='title-block ptb-20 pt-0'>
                    <h1 className="h2"><strong>Best Rated</strong> Hair Transplant Clinic<strong>.</strong></h1>
                    <p><em>Eugenix Hair Sciences</em> is the best hair transplant clinic <br/>because of the services it provides with leading experienced surgeons.</p>
                </div>
                <div className='btnbox'>
                    <Link className='btn secondary white-hover' to='/'>Primary Button</Link>
                    <Link className='btn white-btn white-hover' to='/'>Secondary Button</Link>
                </div>
            </div>
        </Container>
    </section>
    </>
  )
}

export default Banner